
































































































import { OpCardHistory } from '@/models/opcards/OpCardHistory';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { OpUtils } from '@/common/OpUtils';

type ymTyp = {
  index: number;
  label: string;
  value: string;
};

@Component
export default class PointHistoryPanel extends Vue {
  readonly INITIAL_PAGE_NO = 1;

  @PropSync('selectedYm', { required: true })
  syncedSelectedYm!: ymTyp;

  @Prop({ required: true })
  pointHistory!: OpCardHistory[];

  @Prop({ required: false })
  historyMsg!: string;

  @PropSync('pageNo', { required: true })
  syncedPageNo!: number;

  created() {
    this.initialize();
  }

  initialize() {
    this.syncedSelectedYm = this.ymList[0];
  }

  get ymList() {
    let ymList = [];
    for (let i = 0; i < 12; i++) {
      ymList.push({
        index: i,
        value: dayjs()
          .subtract(i, 'month')
          .startOf('month')
          .format('YYYYMMDD'),
        label: dayjs()
          .subtract(i, 'month')
          .startOf('month')
          .format('YYYY年MM月')
      });
    }
    return ymList;
  }

  getWebCardName(webCardCode: any) {
    return OpUtils.getWebCardName(webCardCode as string);
  }

  nextPage() {
    this.syncedPageNo++;
  }

  prevPage() {
    this.syncedPageNo--;
  }

  nextMonth() {
    this.syncedSelectedYm = this.ymList[this.syncedSelectedYm.index - 1];
  }

  prevMonth() {
    this.syncedSelectedYm = this.ymList[this.syncedSelectedYm.index + 1];
  }

  get isLastPage() {
    return this.pointHistory.length !== 50;
  }

  get isFirstPage() {
    return this.syncedPageNo === this.INITIAL_PAGE_NO;
  }

  get isOldestMonth() {
    return this.ymList[11].value === this.syncedSelectedYm.value;
  }

  get isLatestMonth() {
    return this.ymList[0].value === this.syncedSelectedYm.value;
  }
}
