
















































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import OpAppDownloadPanel from '@/components/OpAppDownloadPanel.vue';

@Component({
  components: {
    OpAppDownloadPanel
  }
})
export default class About extends Vue {}
