




































import { Component, Vue, PropSync } from 'vue-property-decorator';
import OpAppDownloadPanel from '@/components/OpAppDownloadPanel.vue';

@Component({
  components: {
    OpAppDownloadPanel
  }
})
export default class CompleteDialog extends Vue {
  @PropSync('isCompleteDialogOpened', { type: Boolean })
  syncedIsCompleteDialogOpened!: boolean;
}
