import { Balance } from './OpCard';
import { DepartmentStorePoint } from './DepartmentStorePoint';

export class PointOverview extends Balance {
  readonly pointAccumulateTermStart: string;
  readonly pointAccumulateTermEnd: string;

  constructor(balance: Balance, departmentStorePoint: DepartmentStorePoint) {
    super(
      balance.processDatetime,
      balance.returnStatus,
      balance.errMessage,
      balance.memberStatus,
      balance.thisYearBalance,
      balance.lastYearBalance,
      balance.previousBalance,
      balance.pointAccumTerm,
      balance.admissionDate,
      balance.pointInvalidDate
    );
    this.pointAccumulateTermStart =
      departmentStorePoint.pointAccumulateTermStart;
    this.pointAccumulateTermEnd = departmentStorePoint.pointAccumulateTermEnd;
  }
}
