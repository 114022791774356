
























































































































































































import TableRow from '@/components/OdakyuPoint/tabs/PasmoCharge/TableRow.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  DisplayStatus,
  SevenbankPasmoCharge,
  MobilePasmoCharge
} from '@/models/PasmoCharge';
import { PasmoChargeRepository } from '@/repositories/PasmoChargeRepository';

@Component({ components: { TableRow } })
export default class InfoPasmoCharge extends Vue {
  // エラーメッセージ
  errMsg = '';
  // ページ番号
  pageNo = 1;
  // ページサイズ 送金参照 API から取得する
  pageSize = 0;
  // 提携先コード 送金参照 API から取得する
  partnerCode = '';
  // チャージデータ 送金参照 API から取得する
  pasmoCharges: (MobilePasmoCharge | SevenbankPasmoCharge)[] = [];
  // 総チャージ件数 送金参照 API から取得する
  totalCount = 0;
  // モーダルフラグ
  isModalOpening = false;
  // モーダルに表示するチャージデータ
  focusedCharge: SevenbankPasmoCharge | null = null;
  // 今月チャージ可能額 チャージ制限情報取得 API から取得する
  availableChargeAmount = 0;
  // 最終更新日時
  readonly now = new Date();

  @Watch('pageNo')
  changePageNo() {
    this.fetchChargeHistory(this.pageNo);
    window.scrollTo(0, 300); // PC・スマートフォン共に適切な位置にスクロールする
  }

  // ページ初回表示時に「今月のチャージ可能残高」「送金履歴(1ページ目)」を取得して表示する
  // ページの行き来に伴って、「今月のチャージ可能残高」は更新しない
  created() {
    this.fetchChargeLimit();
    this.fetchChargeHistory(this.pageNo);
  }

  get repo(): PasmoChargeRepository {
    return new PasmoChargeRepository();
  }

  // fetchChargeLimit チャージ制限情報取得 API から今月のチャージ可能残高を取得
  fetchChargeLimit() {
    this.repo.getPasmoChargeLimit().then(limit => {
      this.availableChargeAmount = limit.availableChargeAmount;
    });
  }

  // fetchChargeHistory 送金参照 API から送金履歴を取得
  fetchChargeHistory(pageNo: number) {
    this.repo
      .getPasmoChargeHistory(pageNo)
      .then(history => {
        this.errMsg = ''; // データ取得に成功した場合、errMsg を空にする
        this.pageSize = history.pageSize; // ページサイズ (固定値)
        this.partnerCode = history.partnerCode; // 提携先コード (固定値)
        this.pasmoCharges = history.charges; // チャージ情報の配列
        this.totalCount = history.totalCount; // ユーザの直近1年間の総チャージ回数
      })
      .catch(() => {
        this.errMsg = this.$msg.get('2000054');
      });
  }

  // チャージ詳細のモーダルを表示する
  showModal(charge: SevenbankPasmoCharge) {
    // 画面上のステータスが「未受取」の場合のみモーダルを表示する
    if (charge.displayStatus !== DisplayStatus.NotReceived) {
      return;
    }
    this.isModalOpening = true;
    this.focusedCharge = charge;
  }

  // get offset 現在のページのオフセットを返す
  // this.pageNo は0でなく1から始まる
  get offset(): number {
    return this.pageSize * (this.pageNo - 1);
  }

  // nextPage pageNo をインクリメントする
  nextPage() {
    this.pageNo++;
  }

  // prevPage pageNo をデクリメントする
  prevPage() {
    this.pageNo--;
  }

  // get hasNextPage 次のページが存在するならば true を返す
  get hasNextPage(): boolean {
    return this.offset + this.pasmoCharges.length < this.totalCount;
  }

  // get hasPrevPage 前のページが存在するならば true を返す
  get hasPrevPage(): boolean {
    return this.offset > 0;
  }
}
