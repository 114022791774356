






































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import OnceButton from '@/components/OnceButton.vue';
import OpCardPanel from '@/components/OdakyuPoint/tabs/OpCards/OpCardPanel.vue';
import RegisterConfirmDialog from '@/components/OdakyuPoint/tabs/OpCards/RegisterConfirmDialog.vue';
import MainCardConfirmDialog from '@/components/OdakyuPoint/tabs/OpCards/MainCardConfirmDialog.vue';
import RevokeConfirmDialog from '@/components/OdakyuPoint/tabs/OpCards/RevokeConfirmDialog.vue';
import OpCardProcessSuccessDialog from '@/components/OdakyuPoint/tabs/OpCards/OpCardProcessSuccessDialog.vue';
import UnsubscribeCheckDialog from '@/components/OdakyuPoint/tabs/OpCards/UnsubscribeCheckDialog.vue';
import UnsubscribeConfirmDialog from '@/components/OdakyuPoint/tabs/OpCards/UnsubscribeConfirmDialog.vue';
import AddOpCardForm from '@/components/OdakyuPoint/tabs/OpCards/AddOpCardForm.vue';
import OpRegisterForm from '@/components/OpRegisterForm/OpRegisterForm.vue';
import OpSecurityCodeRegisterForm from '@/components/OpRegisterForm/OpSecurityCodeRegisterForm.vue';
import { mapState } from 'vuex';
import { OpCardRepository } from '@/repositories/OpCardRepository';
import { OpCardList, OpCard as Card } from '@/models/opcards/OpCard';
import { OpUtils } from '@/common/OpUtils';
import { UserRepository } from '@/repositories/UserRepository';
import { ResCurrentUser } from '@/gen';
import { UserEditForm } from '@/models/forms/UserForm';

@Component({
  components: {
    OnceButton,
    OpCardPanel,
    RegisterConfirmDialog,
    MainCardConfirmDialog,
    RevokeConfirmDialog,
    OpCardProcessSuccessDialog,
    UnsubscribeCheckDialog,
    UnsubscribeConfirmDialog,
    OpSecurityCodeRegisterForm,
    OpRegisterForm,
    AddOpCardForm
  },
  computed: {
    ...mapState(['op'])
  }
})
export default class OpCard extends Vue {
  @Ref() readonly AddOpCardForm!: AddOpCardForm;
  @Ref() readonly OpRegisterForm!: OpRegisterForm;
  @Ref() readonly OpSecurityCodeRegisterForm!: OpSecurityCodeRegisterForm;

  opCardNumber: string[] = ['', '', ''];
  securityCode: string = '';
  cardType: string = '';
  form = {} as UserEditForm;
  btnLoading = false;
  isRegisterConfirmDialogOpening = false;
  isMainCardConfirmDialogOpening = false;
  isRevokeConfirmDialogOpening = false;
  isUnsubscribeCheckDialogOpening = false;
  isUnsubscribeConfirmDialogOpening = false;
  isRevokeCardDigital = false;
  isOpCardProcessSuccessDialogOpening = false;
  isUpdateUserFailed = false;
  doSetMainCard = false;
  doUnsubscribeOpCard = false;
  processCard = {} as Card;
  successDialogMsg = '';
  error = false;

  readonly UNSYNCED_POINT_ONLY_REAL_CARD = 'unsynced-point-only-real';

  readonly CREDIT_CARD = 'credit';

  get opCards() {
    return OpCardList.valueOf(
      this.$auth.user['https://one-odakyu.com/op_cards']
    );
  }

  get sortedOpCards() {
    return this.opCards.sortedOpCards;
  }

  get opCardRepo() {
    return new OpCardRepository();
  }

  get userRepository() {
    return new UserRepository();
  }

  get isRegistOpCard() {
    return this.opCards.validOpCards.length === 1;
  }

  async updateOpcards() {
    await this.$auth.getTokenSilently({ ignoreCache: true });
    this.$store.commit('setOpBalance', await this.opCardRepo.getOpBalance());
  }

  async getAndUpdateCurrentUser() {
    await this.userRepository
      .getCurrentUser()
      .then((res: ResCurrentUser) => {
        this.form = new UserEditForm(res);
      })
      .then(() => {
        return this.userRepository.updateUser(this.form);
      })
      .catch(() => {
        this.isUpdateUserFailed = true;
      });
  }

  async registerConfirmDialog() {
    if (
      (await this.AddOpCardForm.OpRegisterForm.$validator.validateAll()) &&
      (await this.AddOpCardForm.OpSecurityCodeRegisterForm.$validator.validateAll())
    ) {
      this.isRegisterConfirmDialogOpening = true;
    }

    return;
  }

  register() {
    // ボタン連打防止のための btnLoading を確認する
    if (this.btnLoading) {
      return;
    }
    this.btnLoading = true;
    this.opCardRepo
      .postOpCardNo({
        odakyu_customer_no: this.opCardNumber.join(''),
        main_flg: this.doSetMainCard,
        security_cd: !this.securityCode ? undefined : this.securityCode
      })
      .then(async ({ data }) => {
        this.$scrollTo('#opCardPanel');
        this.updateOpcards(); // idトークンの更新およびECOP残高照会
        this.successDialogMsg = this.$msg.get('2000029');
        if (!data.is_attr_registered_to_opsys) {
          await this.getAndUpdateCurrentUser();
        }
        this.cardType = this.cardTypeToNoticeWhenComplete;
      })
      .catch((errCode: number) => {
        this.handleRegisterErr(errCode);
      })
      .finally(() => {
        this.isRegisterConfirmDialogOpening = false;
        this.isOpCardProcessSuccessDialogOpening = true;
        this.opCardNumber = ['', '', ''];
        this.btnLoading = false;
      });
  }

  handleRegisterErr(errCode: number) {
    if (40000 <= errCode && errCode < 40100) {
      this.handleRegister400Err(errCode);
      return;
    }

    switch (errCode) {
      case 40900:
        this.successDialogMsg = this.$msg.get('2000007');
        break;
      case 50003:
        this.successDialogMsg = this.$msg.get('2000202');
        break;
      case 503:
      case 50301:
      case 50302:
        this.successDialogMsg = this.$msg.get('2000010');
        break;
      default:
        this.successDialogMsg = this.$msg.get('2000011');
        break;
    }
  }

  handleRegister400Err(errCode: number) {
    switch (errCode) {
      case 40000:
        this.successDialogMsg = this.$msg.get('2000012');
        break;
      case 40001:
        this.successDialogMsg = this.$msg.get('2000006');
        break;
      case 40004:
        this.successDialogMsg = this.$msg.get('2000201');
        break;
      case 40006:
        this.successDialogMsg = this.$msg.get('2000009');
        break;
      case 40013:
        this.successDialogMsg = this.$msg.get('2000030');
        break;
      case 40024:
        this.successDialogMsg = this.$msg.get('2000200');
        break;
      default:
        this.successDialogMsg = this.$msg.get('2000011');
        break;
    }
  }

  openMainCardConfirmDialog(card: Card) {
    this.isMainCardConfirmDialogOpening = true;
    this.processCard = card;
  }

  setMainCard() {
    this.btnLoading = true;
    this.opCardRepo
      .patchMainOpCard(this.processCard.odakyuCustomerNo)
      .then(async () => {
        await this.updateOpcards(); // idトークンの更新およびECOP残高照会
        this.successDialogMsg = this.$msg.get('2000027');
      })
      .catch((errCode: number) => {
        this.handleSetMainCardErr(errCode);
      })
      .finally(() => {
        this.btnLoading = false;
        this.isMainCardConfirmDialogOpening = false;
        this.isOpCardProcessSuccessDialogOpening = true;
        this.processCard = {} as Card;
      });
  }

  handleSetMainCardErr(errCode: number) {
    switch (errCode) {
      case 40001:
        this.successDialogMsg = this.$msg.get('2000008');
        break;
      case 503:
        this.successDialogMsg = this.$msg.get('2000010');
        break;
      default:
        this.successDialogMsg = this.$msg.get('2000011');
        break;
    }
  }

  openRevokeConfirmDialog(card: Card) {
    this.isRevokeConfirmDialogOpening = true;
    this.isRevokeCardDigital = card.isPointOnlyDigitalCard;
    this.processCard = card;
  }

  clickRevokeCard() {
    this.isRevokeConfirmDialogOpening = false;
    if (
      !this.isRegistOpCard &&
      this.isPointOnlyRealCard &&
      this.doUnsubscribeOpCard
    ) {
      this.isUnsubscribeConfirmDialogOpening = true;
    } else if (this.isRegistOpCard && this.isPointOnlyRealCard) {
      this.isUnsubscribeCheckDialogOpening = true;
    } else {
      this.revokeCard();
    }
  }

  clickCancel() {
    this.doUnsubscribeOpCard = false;
  }

  get isPointOnlyRealCard() {
    return this.processCard.isPointOnlyRealCard;
  }

  get cardTypeToNoticeWhenComplete() {
    if (
      !OpUtils.isPointOnlyRealCard(this.opCardNumber.join('')) &&
      !OpUtils.isPointOnlyDigitalCard(this.opCardNumber.join(''))
    ) {
      return this.CREDIT_CARD;
    }

    if (
      OpUtils.isPointOnlyRealCard(this.opCardNumber.join('')) &&
      this.isUpdateUserFailed
    ) {
      return this.UNSYNCED_POINT_ONLY_REAL_CARD;
    }

    return '';
  }

  checkedUnsubscribeOpCard() {
    this.isUnsubscribeCheckDialogOpening = false;
    if (this.isRegistOpCard && this.doUnsubscribeOpCard) {
      this.isUnsubscribeConfirmDialogOpening = true;
    } else {
      this.revokeCard();
    }
  }

  revokeCard() {
    this.btnLoading = true;
    this.opCardRepo
      .removeOpCardNo({
        odakyu_customer_no: this.processCard.odakyuCustomerNo,
        opsys_unregistration_flg:
          this.processCard.isPointOnlyDigitalCard || this.doUnsubscribeOpCard
      })
      .then(async () => {
        await this.updateOpcards(); // idトークンの更新およびECOP残高照会
        this.successDialogMsg = this.$msg.get('2000028');
      })
      .catch((errCode: number) => {
        this.handleRevokeCardErr(errCode);
      })
      .finally(() => {
        this.isRevokeConfirmDialogOpening = false;
        this.isUnsubscribeCheckDialogOpening = false;
        this.isUnsubscribeConfirmDialogOpening = false;
        this.isRevokeCardDigital = false;
        this.isOpCardProcessSuccessDialogOpening = true;
        this.doUnsubscribeOpCard = false;
        this.processCard = {} as Card;
        this.btnLoading = false;
      });
  }

  handleRevokeCardErr(errCode: number) {
    switch (errCode) {
      case 40002:
        this.successDialogMsg = this.$msg.get('2000012');
        break;
      case 503:
      case 50301:
      case 50302:
        this.successDialogMsg = this.$msg.get('2000010');
        break;
      default:
        this.successDialogMsg = this.$msg.get('2000011');
        break;
    }
  }
}
