import Vue from 'vue';
import * as titanApi from '@/gen/titan/api';
import { DepartmentStorePoint } from '@/models/opcards/DepartmentStorePoint';

export class DepartmentStorePointRepository {
  private async opApiClient() {
    const config = await Vue.prototype.$getTitanConfigWithToken(
      Vue.prototype.$auth
    );
    return new titanApi.OpApi(config);
  }

  async getDepartmentStorePoint(odakyuCustomerNo: string) {
    const client = await this.opApiClient();
    return await client.getDepartmentStorePoint(odakyuCustomerNo).then(res => {
      return DepartmentStorePoint.valueOf(res.data);
    });
  }
}
