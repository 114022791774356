import Vue from 'vue';
import * as titanApi from '@/gen/titan/api';
import { OpCardHistory } from '@/models/opcards/OpCardHistory';

export class OpHistoryRepository {
  private async opApiClient() {
    const config = await Vue.prototype.$getTitanConfigWithToken(
      Vue.prototype.$auth
    );
    return new titanApi.OpApi(config);
  }

  async getPointHistories(
    from: string,
    to: string,
    pageNo: number,
    odakyuCustomerNo: string
  ) {
    const client = await this.opApiClient();
    return await client
      .getOpHistories(from, to, pageNo, odakyuCustomerNo)
      .then(res => {
        return OpCardHistory.valueOf(res.data);
      });
  }
}
