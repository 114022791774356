import Vue from 'vue';
import * as titanApi from '@/gen/titan/api';
import { OpGiftUse } from '@/models/OpGiftUse';

export class OpGiftRepository extends Vue {
  async postOpGift(giftcode: string): Promise<OpGiftUse> {
    const titanConfig = await this.$getTitanConfigWithToken(this.$auth);
    const opApi = new titanApi.OpApi(titanConfig);
    try {
      const res = await opApi.postOpGift({
        gift_cd: giftcode
      });
      const { data } = res;
      return OpGiftUse.valueOf(data);
    } catch (err) {
      const error = err as any;
      const res = error.response;
      throw res.data.code || res.status;
    }
  }
}
