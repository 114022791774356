









































































































import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';

@Component
export default class ConfirmationDialog extends Vue {
  @Prop({ required: true })
  readonly isConfirmationDialogOpened!: boolean;
  @Prop({ required: true })
  readonly exchangeAmount!: number;
  @Prop({ required: true })
  readonly exchangeMile!: number;
  @Prop({ required: true })
  readonly opBalanceAfterExchange!: number;
  @Prop({ required: true })
  readonly isProcessing!: boolean;
  @PropSync('isConfirmationDialogOpened', { type: Boolean })
  syncedIsConfirmationDialogOpened!: boolean;
}
