



































































import { OpCard } from '@/models/opcards/OpCard';
import { PointOverview } from '@/models/opcards/PointOverview';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PointOverviewPanel extends Vue {
  @Prop({ required: true })
  readonly selectedOpCard!: OpCard;

  @Prop({ required: true })
  readonly pointOverview!: PointOverview;

  readonly HYPHEN: string = '-';
}
