































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { OpCard } from '@/models/opcards/OpCard';

@Component
export default class OpCardPanel extends Vue {
  @Prop({ required: true })
  card!: OpCard;

  @Prop({ required: true })
  invalidRevoke!: Boolean;
}
