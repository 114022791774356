








































import { Component, Vue } from 'vue-property-decorator';

@Component
// 臨時メンテナンス対応 redmine : https://nasa.future.co.jp/ode/redmine/issues/4975
export default class Maintenance extends Vue {
  now: Date | null = null;
  get extraordinaryMaintenance(): boolean {
    this.now = new Date();
    if (!this.now) {
      return false;
    }
    return (
      this.now >= new Date('2024-09-08T21:30:00+09:00') &&
      this.now <= new Date('2024-09-09T08:00:00+09:00')
    );
  }
}
// ここまで臨時対応
/* TODO:(t.nakamichi.c7@future.co.jp)臨時メンテナンス終了後に臨時対応箇所を削除し、以下コメントアウトした内容に戻す
export default class Maintenance extends Vue {}
*/
