
















































import { Component, Prop, Vue } from 'vue-property-decorator';
import OnceButton from '@/components/OnceButton.vue';

@Component({
  components: {
    OnceButton
  }
})
export default class RegisterConfirmDialog extends Vue {
  @Prop({ required: true })
  readonly value!: boolean;
  @Prop({ required: true })
  readonly buttonLoading!: boolean;

  get wrapperDialog() {
    return this.value;
  }

  set wrapperDialog(flag: boolean) {
    this.$emit('input', flag);
  }
}
