











import { Component, Prop, Vue } from 'vue-property-decorator';
import { DisplayStatus, MobilePasmoCharge } from '@/models/PasmoCharge';

@Component
export default class TableDataMobile extends Vue {
  @Prop({ required: true })
  readonly charge!: MobilePasmoCharge;
  get isCancelled(): boolean {
    return this.charge.displayStatus === DisplayStatus.Cancelled;
  }
}
