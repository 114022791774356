
















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import InfoPasmoCharge from '@/components/OdakyuPoint/tabs/PasmoCharge/tabs/InfoPasmoCharge.vue';
import ApplySevenbankCharge from '@/components/OdakyuPoint/tabs/PasmoCharge/tabs/ApplySevenbankCharge.vue';
import ApplyMobileCharge from '@/components/OdakyuPoint/tabs/PasmoCharge/tabs/ApplyMobileCharge.vue';
import PasmoAppDownloadPanel from '@/components/PasmoAppDownloadPanel.vue';

@Component({
  components: {
    InfoPasmoCharge,
    ApplySevenbankCharge,
    PasmoAppDownloadPanel,
    ApplyMobileCharge
  }
})
export default class PasmoCharge extends Vue {
  readonly TAB_INFO_PASMO_CHARGE = 'info-pasmo-charge';
  readonly TAB_APPLY_MOBILE_CHARGE = 'apply-mobile-charge';
  readonly TAB_APPLY_SEVENBANK_CHARGE = 'apply-sevenbank-charge';

  /**
   * タブの左からの並び順を定義する。並び順を変更する場合はここを変更すること。
   */
  readonly LIST_TAB_ORDER = [
    this.TAB_INFO_PASMO_CHARGE,
    this.TAB_APPLY_MOBILE_CHARGE,
    this.TAB_APPLY_SEVENBANK_CHARGE
  ];

  /**
   * このページの表示タブをコントロールするための値。
   */
  active_tab = this.LIST_TAB_ORDER.indexOf(this.TAB_INFO_PASMO_CHARGE);

  created() {
    this.doOnCreated();
  }

  doOnCreated() {
    this.handleQueryTab();
  }

  // ページ内でクエリが書き換えられた際に表示タブの出し分けを行う
  @Watch('$route.query.pasmo_charge_tab')
  onQueryErrorChanged() {
    this.handleQueryTab();
  }

  /**
   * 画面ロード時のクエリパラメータによって表示するタブを制御する。
   * string型ではなかった、LIST_TAB_ORDERで定義されていなかった場合は初期化時に定義されている通り「チャージ申請履歴」を表示する
   */
  handleQueryTab() {
    const query = this.$route.query || {};

    // LIST_TAB_ORDERで定義された文字列であれば、初期表示タブを更新する。
    if (
      typeof query.pasmo_charge_tab === 'string' &&
      this.LIST_TAB_ORDER.includes(query.pasmo_charge_tab)
    ) {
      this.active_tab = this.LIST_TAB_ORDER.indexOf(query.pasmo_charge_tab);
      return;
    }
  }

  /**
   * v-tabs-itemsをクリックしたときに、クエリパラメータの値を変更する。
   * このコンポーネント内で直接クエリパラメータを変更すると、
   * 小田急ポイント画面の他タブでページを開いた際にもクエリパラメータが上書きされるため、
   * クエリパラメータの制御は小田急ポイント画面に委ねる。
   */
  updateQueryTabValue() {
    this.$nextTick(() =>
      this.$emit(
        'updateQueryPasmoChargeActiveTabValue',
        this.LIST_TAB_ORDER[this.active_tab]
      )
    );
  }
}
