import {
  ResPasmoChargeHistory,
  ResPasmoChargeHistoryCharges,
  ResPasmoChargeLimit,
  ResSevenbankRegister
} from '@/gen/titan';

export class PasmoChargeSevenbankRegister {
  private constructor(readonly confNo: string, readonly partnerCode: string) {}

  static valueOf(data: ResSevenbankRegister) {
    return new PasmoChargeSevenbankRegister(data.conf_no, data.partner_code);
  }
}

export class PasmoChargeLimit {
  private constructor(
    readonly monthlyChargeLimit: number,
    readonly monthlyChargeAmount: number,
    readonly SevenMinimumAmount: number,
    readonly SevenUnitAmount: number,
    readonly SevenChargeFee: number,
    readonly MobilePasmoMinimumAmount: number,
    readonly MobilePasmoUnitAmount: number,
    readonly MobilePasmoChargeFee: number
  ) {}

  static valueOf(data: ResPasmoChargeLimit) {
    return new PasmoChargeLimit(
      data.monthly_charge_limit,
      data.monthly_charge_amount,
      data.seven_minimum_amount,
      data.seven_unit_amount,
      data.seven_charge_fee,
      data.mobile_pasmo_minimum_amount,
      data.mobile_pasmo_unit_amount,
      data.mobile_pasmo_charge_fee
    );
  }

  // 今月チャージ申請可能ポイント
  // マイナス値になる場合は0を返す
  get availableChargeAmount() {
    const amount = this.monthlyChargeLimit - this.monthlyChargeAmount;
    if (amount < 0) {
      return 0;
    }
    return amount;
  }
}

// チャージタイプ
export const ChargeTyp = {
  Sevenbank: '1',
  MobilePasmo: '2'
} as const;

// 画面に表示するステータス
export const DisplayStatus = {
  NotReceived: 1, // 未受取
  Processing: 2, // 処理中
  Received: 3, // チャージ済
  Cancelling: 4, // 取消中
  Cancelled: 5, // 取消済
  Expired: 6 // 受取期限切れ
} as const;
export type DisplayStatus = typeof DisplayStatus[keyof typeof DisplayStatus];

function getDisplayStatusString(status: DisplayStatus): string {
  switch (status) {
    case DisplayStatus.NotReceived:
      return '未受取';
    case DisplayStatus.Processing:
      return '処理中';
    case DisplayStatus.Received:
      return 'チャージ済';
    case DisplayStatus.Cancelling:
      return '取消中';
    case DisplayStatus.Cancelled:
      return '取消済';
    case DisplayStatus.Expired:
      return '受取期限切れ';
  }
}

// セブン銀行送金ステータス
export const SevenbankRemittanceStatus = {
  Hontouroku: '10', // 本登録
  Shiharaichu: '20', // 支払中
  Soukinkanryo: '30', // 送金完了
  Hoseikanryo: '31', // 補正完了
  Kigengire: '40', // 期限切れ
  Torikeshi: '50' // 取消
} as const;
export type SevenbankRemittanceStatus = typeof SevenbankRemittanceStatus[keyof typeof SevenbankRemittanceStatus];

export class SevenbankPasmoCharge {
  // 画面に表示するチャージタイプ
  readonly chargeTypString: string = 'ATM';

  // `atmUsedDt` のみ null が許容される
  // それ以外のパラメータは送金参照 API から必須で返却される
  private constructor(
    readonly amount: number,
    readonly tradeDt: Date,
    readonly confirmationNo: string,
    readonly atmUsedDt: Date | null,
    readonly receivingExpirationYmd: Date,
    readonly remittanceStatus: SevenbankRemittanceStatus
  ) {}

  // 画面に表示するステータスを返す
  get displayStatus(): DisplayStatus {
    switch (this.remittanceStatus) {
      case SevenbankRemittanceStatus.Hontouroku:
      case SevenbankRemittanceStatus.Shiharaichu:
        return DisplayStatus.NotReceived; // 未受取
      case SevenbankRemittanceStatus.Soukinkanryo:
      case SevenbankRemittanceStatus.Hoseikanryo:
        return DisplayStatus.Received; // チャージ済
      case SevenbankRemittanceStatus.Kigengire:
        return DisplayStatus.Expired; // 受取期限切れ
      case SevenbankRemittanceStatus.Torikeshi:
        return DisplayStatus.Cancelled; // 取消済
    }
  }

  // 画面に表示するステータス文言を返す
  get displayStatusString(): string {
    return getDisplayStatusString(this.displayStatus);
  }

  // 送金参照 API のレスポンスに含まれる `ResPasmoChargeHistoryCharges` には、セブン銀行チャージのデータと、
  // モバイル PASMO チャージのデータが含まれる予定である。
  // セブン銀行チャージのデータとして不適切な値が引数に与えられた時、`valueOf` は null を返す。
  static valueOf(c: ResPasmoChargeHistoryCharges): SevenbankPasmoCharge | null {
    // `charge_typ` が適切であることを確認
    if (c.charge_typ !== ChargeTyp.Sevenbank) {
      return null;
    }

    // `confirmation_no`, `receiving_expiration_ymd`, `remittance_status` が
    // 非 null であることを確認
    if (
      c.confirmation_no === null ||
      c.receiving_expiration_ymd === null ||
      c.remittance_status === null
    ) {
      return null;
    }

    // `remittanceStatus` が `SevenbankRemittanceStatus` であることを確認
    const remittanceStatus = ((
      status: string
    ): SevenbankRemittanceStatus | null => {
      if (
        status === SevenbankRemittanceStatus.Hontouroku ||
        status === SevenbankRemittanceStatus.Shiharaichu ||
        status === SevenbankRemittanceStatus.Soukinkanryo ||
        status === SevenbankRemittanceStatus.Hoseikanryo ||
        status === SevenbankRemittanceStatus.Kigengire ||
        status === SevenbankRemittanceStatus.Torikeshi
      ) {
        return status;
      }
      return null;
    })(c.remittance_status);
    if (remittanceStatus === null) {
      return null;
    }

    // インスタンスを生成
    return new SevenbankPasmoCharge(
      c.amount,
      new Date(c.trade_dt), // `trade_dt` は RFC 3339 文字列である
      c.confirmation_no,
      c.atm_used_dt === null ? null : new Date(c.atm_used_dt), // `atm_used_dt` が与えられる場合、それは RFC 3339 文字列である
      new Date(c.receiving_expiration_ymd), // `receiving_expiration_ymd` RFC 3339 文字列である
      remittanceStatus
    );
  }
}

// モバイルPASMOチャージステータス
export const MobilePasmoChargeStatus = {
  Seikou: '00', // 成功
  Shippai: '10', // 失敗
  Seihifumei: '20', // 成否不明
  Rikabarizumi: '30' // リカバリ済み
} as const;
export type MobilePasmoChargeStatus = typeof MobilePasmoChargeStatus[keyof typeof MobilePasmoChargeStatus];

export class MobilePasmoCharge {
  // 画面に表示するチャージタイプ
  readonly chargeTypString: string = 'アプリ';

  // `recoveryDt` のみ null が許容される
  // それ以外のパラメータは送金参照 API から必須で返却される
  private constructor(
    readonly amount: number,
    readonly tradeDt: Date,
    readonly chargeStatus: MobilePasmoChargeStatus,
    readonly recoveryDt: string | null
  ) {}

  // 画面に表示するステータスを返す
  get displayStatus(): DisplayStatus {
    switch (this.chargeStatus) {
      case MobilePasmoChargeStatus.Seikou:
        return DisplayStatus.Received; // チャージ済
      case MobilePasmoChargeStatus.Shippai:
        return DisplayStatus.Cancelling; // 取消中
      case MobilePasmoChargeStatus.Seihifumei:
        return DisplayStatus.Processing; // 処理中
      case MobilePasmoChargeStatus.Rikabarizumi:
        return DisplayStatus.Cancelled; // 取消済
      default:
        return DisplayStatus.Processing; // 処理中
    }
  }

  // 画面に表示するステータス文言を返す
  get displayStatusString(): string {
    return getDisplayStatusString(this.displayStatus);
  }

  // `ResPasmoChargeHistoryCharges` にはセブン銀行チャージとモバイルPASMOチャージのデータが含まれる
  // モバイルPASMOチャージのデータとして不適切な値が引数に与えられた時、`valueOf` は null を返す。
  static valueOf(c: ResPasmoChargeHistoryCharges): MobilePasmoCharge | null {
    // `charge_typ` が適切であることを確認
    if (c.charge_typ !== ChargeTyp.MobilePasmo) {
      return null;
    }

    // `charge_status`が 非 null であることを確認
    if (c.charge_status === null) {
      return null;
    }

    // `chargeStatus` が `MobilePasmoChargeStatus` であることを確認
    const chargeStatus = ((status: string): MobilePasmoChargeStatus | null => {
      if (
        status === MobilePasmoChargeStatus.Seikou ||
        status === MobilePasmoChargeStatus.Shippai ||
        status === MobilePasmoChargeStatus.Seihifumei ||
        status === MobilePasmoChargeStatus.Rikabarizumi
      ) {
        return status;
      }
      return null;
    })(c.charge_status);
    if (chargeStatus === null) {
      return null;
    }

    // インスタンスを生成
    return new MobilePasmoCharge(
      c.amount,
      new Date(c.trade_dt), // `trade_dt` は RFC 3339 文字列である
      chargeStatus,
      c.recovery_dt
    );
  }
}

export class PasmoChargeHistory {
  private constructor(
    readonly partnerCode: string,
    readonly totalCount: number,
    readonly pageSize: number,
    readonly pageNo: number,
    readonly charges: (MobilePasmoCharge | SevenbankPasmoCharge)[]
  ) {}

  static valueOf(h: ResPasmoChargeHistory): PasmoChargeHistory {
    const charges: (MobilePasmoCharge | SevenbankPasmoCharge)[] = [];
    h.charges
      .map(c => {
        switch (c.charge_typ) {
          case ChargeTyp.Sevenbank:
            return SevenbankPasmoCharge.valueOf(c);
          case ChargeTyp.MobilePasmo:
            return MobilePasmoCharge.valueOf(c);
          default:
            return null;
        }
      })
      .forEach(c => {
        if (c !== null) {
          charges.push(c);
        }
      });

    return new PasmoChargeHistory(
      h.partner_code,
      h.total_count,
      h.page_size,
      h.page_no,
      charges
    );
  }
}
