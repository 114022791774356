import { ResGetDepartmentStorePoint } from '@/gen/titan';

export class DepartmentStorePoint {
  private constructor(
    readonly thisYearPurchaseAmount: string,
    readonly thisYearTotalRate: string,
    readonly pointAccumulateTermStart: string,
    readonly pointAccumulateTermEnd: string,
    readonly calculatedDate: string
  ) {}

  static valueOf(data: ResGetDepartmentStorePoint) {
    return new DepartmentStorePoint(
      data.this_year_purchase_amount,
      data.this_year_total_rate,
      data.point_accumulate_term_start,
      data.point_accumulate_term_end,
      data.calculated_date
    );
  }
}
