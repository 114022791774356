


























































































































import { Vue, Component } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import Forbidden from '@/components/OdakyuPoint/tabs/JalMileExchange/Forbidden.vue';
import PointOverviewPanel from '@/components/OdakyuPoint/tabs/JalMileExchange/PointOverviewPanel.vue';
import PointHasErr from '@/components/OdakyuPoint/PointHasErr.vue';
import ConfirmationDialog from '@/components/OdakyuPoint/tabs/JalMileExchange/ConfirmationDialog.vue';
import CompleteDialog from '@/components/OdakyuPoint/tabs/JalMileExchange/CompleteDialog.vue';
import InputNumber from '@/components/InputNumber.vue';
import { OpCardRepository } from '@/repositories/OpCardRepository';
import { OpCardList } from '@/models/opcards/OpCard';

@Component({
  components: {
    Forbidden,
    PointOverviewPanel,
    PointHasErr,
    ConfirmationDialog,
    CompleteDialog,
    InputNumber
  },
  computed: {
    ...mapGetters(['getOpBalanceSuccess'])
  }
})
export default class JalMileExchange extends Vue {
  // 交換ポイント数の最小値
  readonly MIN_EXCHANGE_AMOUNT = 2000;
  // 交換ポイント数単位
  readonly EXCHANGE_UNIT = 2000;
  // 換算レート
  readonly EXCHANGE_RATE_OP_PER_MILE = 2;
  // 交換例の小田急ポイント数
  readonly EXCHANGE_EXAMPLE_OP = 2000;

  // ユーザが入力する交換ポイント数
  exchangeAmount = 0;
  // 入力欄に表示するためのコンマ付き交換ポイント数の数値文字列
  exchangeAmountInput = '';
  // 登録ボタンの連打を防ぐための、処理中フラグ
  isProcessing = false;
  // 確認モーダルを表示するか
  isConfirmationDialogOpened = false;
  // 交換申し込み完了モーダルを表示するか
  isCompleteDialogOpened = false;
  // 入力値に関するエラーメッセージ
  formErrMsg = '';
  // ポイント→マイル交換申込時エラーメッセージ
  pageErrMsg = '';
  // 入力値が交換ポイントとして正当であるか
  isValidExchangeAmount = false;

  get opCards() {
    return OpCardList.valueOf(
      this.$auth.user['https://one-odakyu.com/op_cards']
    );
  }

  // 現在のOP残高
  // any型で定義されているため、Number型に変換して返す
  get opBalance() {
    return Number(this.$store.state.op.balance.previousBalance);
  }

  get opCardRepo() {
    return new OpCardRepository();
  }

  get exchangeMile(): number {
    return this.exchangeAmount / this.EXCHANGE_RATE_OP_PER_MILE;
  }

  get opBalanceAfterExchange(): number {
    return this.opBalance - this.exchangeAmount;
  }

  // 交換ポイント数バリデーションおよび計算表示更新を行う
  validateInput(): boolean {
    const res = this.inputValidator();
    this.isValidExchangeAmount = res;
    return res;
  }

  inputValidator(): boolean {
    this.formErrMsg = '';

    // 交換ポイント数が入力されていない
    if (!this.exchangeAmountInput) {
      this.formErrMsg = this.$msg.get('2000204');
      return false;
    }

    // 交換ポイント数が半角数字でない
    if (!/^[\d,]*$/.test(this.exchangeAmountInput)) {
      this.formErrMsg = this.$msg.get('2000205');
      return false;
    }

    // 交換ポイント数が最低交換ポイント数未満
    if (this.exchangeAmount < this.MIN_EXCHANGE_AMOUNT) {
      this.formErrMsg = this.$msg.get('2000206', {
        num: this.MIN_EXCHANGE_AMOUNT
      });
      return false;
    }

    // 交換ポイント数が小田急ポイント残高より大きい
    if (this.exchangeAmount > this.opBalance) {
      this.formErrMsg = this.$msg.get('2000208');
      return false;
    }

    // 交換ポイント数が交換可能ポイント数単位でない
    // Number.MAX_SAFE_INTEGER を超えると剰余計算が正しくなくなるため、小田急ポイント残高チェックのあとにチェックする
    if (this.exchangeAmount % this.EXCHANGE_UNIT !== 0) {
      this.formErrMsg = this.$msg.get('2000207', {
        num: this.EXCHANGE_UNIT
      });
      return false;
    }

    return true;
  }

  openConfirmationDialog() {
    this.isConfirmationDialogOpened = true;
  }

  closeConfirmationDialog() {
    // 処理待ちの間はモーダルを閉じられないよう制御する
    if (this.isProcessing) {
      return;
    }
    this.isConfirmationDialogOpened = false;
  }

  openCompleteDialog() {
    this.isConfirmationDialogOpened = false;
    this.isCompleteDialogOpened = true;
    this.exchangeAmount = 0;
    this.exchangeAmountInput = '';
  }

  closeCompleteDialog() {
    this.isCompleteDialogOpened = false;
  }

  register() {
    // ボタン連打防止のための isProcessing を確認する
    if (this.isProcessing) {
      return;
    }
    this.isProcessing = true;

    this.opCardRepo
      .postJalMileExchange(this.exchangeAmount)
      .then(() => this.openCompleteDialog())
      .catch((errCode: number) => {
        this.handlePostJalMileExchangeErr(errCode);
      })
      .then(() => this.opCardRepo.getOpBalance())
      .then(balance => {
        this.$store.commit('setOpBalance', balance);
      })
      .finally(() => {
        this.isProcessing = false;
        this.closeConfirmationDialog();
      });
  }

  handlePostJalMileExchangeErr(errCode: number) {
    switch (errCode) {
      case 40026:
      case 40027:
        this.pageErrMsg = this.$msg.get('2000012');
        break;
      default:
        this.pageErrMsg = this.$msg.get('2000011');
        break;
    }
    this.$scrollTo('#title');
  }
}
