
























































import { Component, Vue } from 'vue-property-decorator';
import { OpCardList } from '@/models/opcards/OpCard';

@Component
export default class Forbidden extends Vue {
  get opCards() {
    return OpCardList.valueOf(
      this.$auth.user['https://one-odakyu.com/op_cards']
    );
  }
}
