























import TableDataSevenbank from '@/components/OdakyuPoint/tabs/PasmoCharge/TableDataSevenbank.vue';
import TableDataMobilePasmo from '@/components/OdakyuPoint/tabs/PasmoCharge/TableDataMobilePasmo.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SevenbankPasmoCharge, MobilePasmoCharge } from '@/models/PasmoCharge';

@Component({ components: { TableDataSevenbank, TableDataMobilePasmo } })
export default class TableRow extends Vue {
  @Prop({ required: true })
  readonly charge!: SevenbankPasmoCharge | MobilePasmoCharge;

  @Prop({ required: true })
  readonly showModal!: (charge: SevenbankPasmoCharge) => void;

  get isChargeSevenbank(): boolean {
    return this.charge instanceof SevenbankPasmoCharge;
  }
  get isChargeMobilePasmo(): boolean {
    return this.charge instanceof MobilePasmoCharge;
  }
}
