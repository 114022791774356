































import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import filterUtils from '@/common/filterUtils';

@Component
export default class InputNumber extends Vue {
  // Vuetify の挙動を再現するためのCSSクラス
  readonly IMITATED_VUETIFY_FOCUSED_CLASSES = [
    'v-input--is-focused',
    'primary--text'
  ];

  @Prop()
  readonly disabled!: boolean;
  @PropSync('amount', { type: Number })
  syncedAmount!: number;
  @PropSync('amountInput', { type: String })
  syncedAmountInput!: String;
  // Vuetify の挙動を再現するためのCSSクラス
  imitatedVuetifyFocusedClasses: string[] = [];

  // スマートフォンで数字キーボードを表示させるために、<input pattern="\d*" /> としている。
  // この影響で、<input> 入力内容にカンマが含まれる場合、Enter 押下時にブラウザの機能により、
  // 入力内容が正規表現に一致しない旨のエラーメッセージが表示される。
  // これを避けるために event.preventDefault() する。
  onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  onFocus() {
    this.imitatedVuetifyFocusedClasses = this.IMITATED_VUETIFY_FOCUSED_CLASSES;
  }

  onBlur() {
    this.imitatedVuetifyFocusedClasses = [];
    this.formatFullToHalfWidth();
    this.$emit('validateInput');
  }

  formatFullToHalfWidth() {
    const s = filterUtils.funcs.formatToHalfUpperCase(this.syncedAmountInput);
    this.syncedAmountInput = s;
    this.syncedAmount = Number(s) || 0;
  }
}
