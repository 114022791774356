




























import { Component, Prop, Vue } from 'vue-property-decorator';
import { DisplayStatus, SevenbankPasmoCharge } from '@/models/PasmoCharge';

@Component
export default class TableDataSevenbank extends Vue {
  @Prop({ required: true })
  readonly charge!: SevenbankPasmoCharge;

  @Prop({ required: true })
  readonly showModal!: (charge: SevenbankPasmoCharge) => void;

  get isReceived(): boolean {
    return this.charge.displayStatus === DisplayStatus.Received;
  }

  get isNotReceived(): boolean {
    return this.charge.displayStatus === DisplayStatus.NotReceived;
  }
}
