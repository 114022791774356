

















































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import PointHistoryPanel from '@/components/OdakyuPoint/tabs/PointHistory/PointHistoryPanel.vue';
import PointOverviewPanel from '@/components/OdakyuPoint/tabs/PointHistory/PointOverviewPanel.vue';
import PointHasErr from '@/components/OdakyuPoint/PointHasErr.vue';
import OpAppDownloadPanel from '@/components/OpAppDownloadPanel.vue';
import DepartmentStorePointPanel from '@/components/OdakyuPoint/tabs/PointHistory/DepartmentStorePointPanel.vue';
import { OpHistoryRepository } from '@/repositories/OpHistoryRepository';
import { DepartmentStorePointRepository } from '@/repositories/DepartmentStorePointRepository';
import { DepartmentStorePoint } from '@/models/opcards/DepartmentStorePoint';
import { OpCardHistoryRecord } from '@/models/opcards/OpCardHistory';
import { Balance, OpCard, OpCardList } from '@/models/opcards/OpCard';
import { PointOverview } from '@/models/opcards/PointOverview';
import { OpCardRepository } from '@/repositories/OpCardRepository';
import dayjs from 'dayjs';
import { AxiosError } from 'axios';
import NewsScroll from '@/components/NewsScroll.vue';
import CampaignScroll from '@/components/CampaignScroll.vue';
import StartNav from '@/components/StartNav.vue';

type ymTyp = {
  index: number;
  label: string;
  value: string;
};

@Component({
  components: {
    PointHistoryPanel,
    PointOverviewPanel,
    PointHasErr,
    DepartmentStorePointPanel,
    OpAppDownloadPanel,
    NewsScroll,
    CampaignScroll,
    StartNav
  }
})
export default class PointHistory extends Vue {
  readonly INITIAL_PAGE_NO = 1;

  selectedYm = {} as ymTyp;
  selectedOpCard = {} as OpCard;
  pointHistory: OpCardHistoryRecord[] = [];
  pointBalance = {} as Balance;
  departmentStorePoint = {} as DepartmentStorePoint;
  historyMsg = '';
  departmentMsg = '';
  pageNo = this.INITIAL_PAGE_NO;

  created() {
    this.initialize();
  }

  initialize() {
    this.selectedOpCard = this.opcards.selectMainCard;
    this.getBalance();
    this.getOpHistory();
    this.getDepartmentStorePoint();
  }

  get opcards() {
    return OpCardList.valueOf(
      this.$auth.user['https://one-odakyu.com/op_cards']
    );
  }

  get doShowMyJCB() {
    return this.selectedOpCard.isJCBCard || this.selectedOpCard.isJCICard;
  }

  get doShowMUFGWeb() {
    return (
      this.selectedOpCard.isMasterCard ||
      this.selectedOpCard.isVISACard ||
      this.selectedOpCard.isOPCMembersCard
    );
  }

  get pointOverview() {
    return new PointOverview(this.pointBalance, this.departmentStorePoint);
  }

  getBalance() {
    this.opCardRepo
      .getOpBalanceByOp(this.selectedOpCard.odakyuCustomerNo)
      .then(res => {
        this.pointBalance = res;
      });
  }

  getOpHistory() {
    this.OpHistoriesRepository.getPointHistories(
      this.selectedYm.value,
      dayjs(this.selectedYm.value)
        .endOf('month')
        .format('YYYYMMDD'),
      this.pageNo,
      this.selectedOpCard.odakyuCustomerNo
    )
      .then(res => {
        this.pointHistory = res;
        this.historyMsg = this.pointHistory.length
          ? ''
          : this.$msg.get('2000024');
      })
      .catch((err: AxiosError) => {
        this.pointHistory = [] as OpCardHistoryRecord[];
        this.historyMsg =
          err.response?.status === 503
            ? this.$msg.get('2000010')
            : this.$msg.get('2000026');
      });
  }

  getDepartmentStorePoint() {
    this.DepartmentStorePointRepository.getDepartmentStorePoint(
      this.selectedOpCard.odakyuCustomerNo
    )
      .then(res => {
        this.departmentStorePoint = res;
      })
      .catch((err: AxiosError) => {
        this.departmentStorePoint = {} as DepartmentStorePoint;
        this.departmentMsg =
          err.response?.status === 503
            ? this.$msg.get('2000010')
            : err.response?.data.code === 50029
            ? this.$msg.get('2000212')
            : this.$msg.get('2000211');
      });
  }

  @Watch('selectedYm')
  changeMonth() {
    this.pageNo = this.INITIAL_PAGE_NO;
    this.getOpHistory();
  }

  @Watch('selectedOpCard')
  changeOpCard() {
    this.getBalance();
    this.getOpHistory();
    this.getDepartmentStorePoint();
  }

  @Watch('pageNo')
  changePageNo() {
    this.getOpHistory();
  }

  get OpHistoriesRepository() {
    return new OpHistoryRepository();
  }

  get opCardRepo() {
    return new OpCardRepository();
  }

  get DepartmentStorePointRepository() {
    return new DepartmentStorePointRepository();
  }
}
