



































































import { Component, Prop, Vue } from 'vue-property-decorator';
import OnceButton from '@/components/OnceButton.vue';

@Component({
  components: {
    OnceButton
  }
})
export default class OpCardProcessSuccessDialog extends Vue {
  @Prop({ required: true })
  readonly value!: boolean;

  @Prop({ required: true })
  readonly successDialogMsg!: string;

  @Prop({ required: true })
  readonly buttonLoading!: boolean;

  @Prop({ required: true })
  readonly cardType!: boolean;

  readonly UNSYNCED_POINT_ONLY_REAL_CARD = 'unsynced-point-only-real';

  readonly CREDIT_CARD = 'credit';

  get wrapperDialog() {
    return this.value;
  }

  set wrapperDialog(flag: boolean) {
    this.$emit('input', flag);
  }
}
