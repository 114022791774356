import Vue from 'vue';
import * as titanApi from '@/gen/titan/api';
import {
  PasmoChargeHistory,
  PasmoChargeLimit,
  PasmoChargeSevenbankRegister
} from '@/models/PasmoCharge';

export class PasmoChargeRepository {
  private async pasmoChargeClient() {
    const config = await Vue.prototype.$getTitanConfigWithToken(
      Vue.prototype.$auth
    );
    return new titanApi.PasmoChargeApi(config);
  }

  async postPasmoChargeSevenbankRegister(
    sevenbankRegisterReqBody: titanApi.ReqSevenbankRegister | undefined
  ) {
    const client = await this.pasmoChargeClient();
    try {
      const res = await client.postSevenbankRegister(sevenbankRegisterReqBody);
      const { data } = res;
      return Promise.resolve(PasmoChargeSevenbankRegister.valueOf(data));
    } catch (err) {
      const res = (err as any).response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }

  async postMobilePasmoCharge(
    odakyuCustomerNo: string,
    opBalance: number,
    chargeAmount: number,
    pasmoToken: string,
    pasmoBalance: number,
    idi: string,
    isAnonymous: boolean
  ) {
    const client = await this.pasmoChargeClient();
    try {
      await client.postMobilepasmoCharge({
        odakyu_customer_no: odakyuCustomerNo,
        op_balance: opBalance,
        charge_amount: chargeAmount,
        pasmo_token: pasmoToken,
        pasmo_balance: pasmoBalance,
        idi: idi,
        is_anonymous: isAnonymous
          ? titanApi.ReqMobilepasmoChargeIsAnonymousEnum._1
          : titanApi.ReqMobilepasmoChargeIsAnonymousEnum._0
      });
    } catch (err) {
      const res = (err as any).response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }

  async getPasmoChargeHistory(pageNo: number) {
    const client = await this.pasmoChargeClient();
    try {
      const res = await client.getPasmoChargeHistory(pageNo);
      return PasmoChargeHistory.valueOf(res.data);
    } catch (err) {
      const res = (err as any).response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }

  async getPasmoChargeLimit() {
    const client = await this.pasmoChargeClient();
    try {
      const res = await client.getPasmoChargeLimit();
      const { data } = res;
      return Promise.resolve(PasmoChargeLimit.valueOf(data));
    } catch (err) {
      const res = (err as any).response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }
}
