





















import { OpCard } from '@/models/opcards/OpCard';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PointHasErr extends Vue {
  @Prop({ required: true })
  readonly selectedOpCard!: OpCard;
}
