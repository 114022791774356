























































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Location } from 'vue-router';
import PointHistory from '@/components/OdakyuPoint/tabs/PointHistory.vue';
import OpCard from '@/components/OdakyuPoint/tabs/OpCard.vue';
import PasmoCharge from '@/components/OdakyuPoint/tabs/PasmoCharge.vue';
import OpGift from '@/components/OdakyuPoint/tabs/OpGift.vue';
import About from '@/components/OdakyuPoint/tabs/About.vue';
import JalMileExchange from '@/components/OdakyuPoint/tabs/JalMileExchange.vue';
import LoginButtonPanel from '@/components/OdakyuPoint/LoginButtonPanel.vue';

@Component({
  components: {
    PointHistory,
    OpCard,
    PasmoCharge,
    OpGift,
    JalMileExchange,
    About,
    LoginButtonPanel
  }
})
export default class P0213 extends Vue {
  readonly TAB_POINT_HISTORY = 'point-history';
  readonly TAB_OP_CARD = 'op-card';
  readonly TAB_PASMO_CHARGE = 'pasmo-charge';
  readonly TAB_OP_GIFT = 'op-gift';
  readonly TAB_JAL_MILE_EXCHANGE = 'jal-mile-exchange';
  readonly TAB_ABOUT = 'about';
  readonly TAB_AFTER_LOGIN = [
    this.TAB_POINT_HISTORY,
    this.TAB_OP_CARD,
    this.TAB_PASMO_CHARGE,
    this.TAB_OP_GIFT,
    this.TAB_JAL_MILE_EXCHANGE
  ];
  readonly TAB_FORCE_LOGIN = [this.TAB_JAL_MILE_EXCHANGE];

  /**
   * タブの左からの並び順を定義する。並び順を変更する場合はここを変更すること。
   */
  readonly LIST_TAB_ORDER = [
    this.TAB_POINT_HISTORY,
    this.TAB_OP_CARD,
    this.TAB_PASMO_CHARGE,
    this.TAB_OP_GIFT,
    this.TAB_JAL_MILE_EXCHANGE,
    this.TAB_ABOUT
  ];

  /**
   * このページの表示タブをコントロールするための値。
   */
  active_tab = this.LIST_TAB_ORDER.indexOf(this.TAB_POINT_HISTORY);
  pasmo_charge_active_tab_query = '';
  show_contents = true;

  created() {
    this.doOnCreated();
  }

  doOnCreated() {
    this.handleQueryTab();
  }

  // ページ内でクエリが書き換えられた際に表示タブの出し分けを行う
  @Watch('$route.query')
  onQueryErrorChanged() {
    this.handleQueryTab();
  }

  //ログイン判定
  get isAuthenticatedOk() {
    return this.$auth.isAuthenticated && !this.$auth.loading;
  }

  /**
   * 画面ロード時のクエリパラメータによって表示するタブを制御する。
   * string型ではなかった、LIST_TAB_ORDERで定義されていなかった場合、ログイン状態の時は「OP参照」を表示する
   */
  handleQueryTab() {
    const query = this.$route.query || {};
    if (!this.isAuthenticatedOk) {
      // 未ログイン状態でアクセスすると、ログインを要求する画面の場合、ログイン画面に遷移する
      if (
        typeof query.tab === 'string' &&
        this.TAB_FORCE_LOGIN.includes(query.tab)
      ) {
        // ログイン画面に遷移する前にコンテンツが表示されないよう制御する
        this.show_contents = false;
        this.$auth.loginWithRedirect({
          appState: { targetUrl: this.$route.fullPath }
        });
        return;
      }
      // 未ログイン状態でログインを要求しない画面にアクセスした場合、クエリを消去する（コンテンツはクエリに依存せず未ログイン用の値が表示されているため変動しない）
      this.updateLocation({});
      return;
    }

    if (query.pasmo_charge_tab && !Array.isArray(query.pasmo_charge_tab)) {
      this.pasmo_charge_active_tab_query = query.pasmo_charge_tab;
    }

    // LIST_TAB_ORDERで定義された文字列であれば、初期表示タブを更新する。
    if (
      typeof query.tab === 'string' &&
      this.LIST_TAB_ORDER.includes(query.tab)
    ) {
      this.active_tab = this.LIST_TAB_ORDER.indexOf(query.tab);
      return;
    }
    this.active_tab = this.LIST_TAB_ORDER.indexOf(this.TAB_POINT_HISTORY);
  }

  /**
   * v-tabs-itemsをクリックしたときに、クエリパラメータの値を変更する。
   * PASMOチャージタブ用のクエリパラメータも合わせて変更するため、現在のタブに応じて処理を分岐している。
   */
  updateQueryTabValue() {
    const query =
      this.LIST_TAB_ORDER[this.active_tab] !== this.TAB_PASMO_CHARGE
        ? {
            tab: this.LIST_TAB_ORDER[this.active_tab],
            giftcode: this.$route.query.giftcode
          }
        : {
            tab: this.LIST_TAB_ORDER[this.active_tab],
            pasmo_charge_tab: this.pasmo_charge_active_tab_query
          };
    this.updateLocation({
      query: query
    });
  }

  updateLocation(location: Location) {
    this.$router.push(location).catch(() => {
      // 現在と同じページにvue-routerで移動しようとしたときにNavigationDuplicatedエラーが発生するが、クエリパラメータを変更するためには仕方ないのでエラーを許容する。
    });
  }

  clickLogin() {
    this.$auth.loginWithRedirect({ appState: { targetUrl: '/odakyu-point' } });
  }

  clickSignup() {
    this.$auth.loginWithRedirect({ initialDisplay: 'signup' });
  }

  /**
   * PASMOチャージタブのv-tabs-itemsをクリックしたときに、クエリパラメータの値を変更する。
   */
  updateQueryPasmoChargeActiveTabValue(pasmo_charge_active_tab_query: string) {
    this.pasmo_charge_active_tab_query = pasmo_charge_active_tab_query;
    this.updateQueryTabValue();
  }
}
