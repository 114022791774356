






























































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DepartmentStorePointPanel extends Vue {
  @Prop({ required: true })
  readonly departmentStorePoint!: any;

  @Prop({ required: false })
  departmentMsg!: string;

  readonly HYPHEN: string = '-';
}
