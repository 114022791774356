import { ResPostOpGift } from '@/gen/titan';

export interface OpGiftBonus {
  name: string; //ボーナスポイント名
  pointCount: number; //ボーナスポイント数
}

export class OpGiftUse {
  static valueOf(data: ResPostOpGift) {
    return new OpGiftUse(
      data.total_point_cnt,
      data.normal_point_cnt,
      data.total_bonus_point_cnt,
      data.bonus_list.map(item => ({
        name: item.name,
        pointCount: item.point_cnt
      })),
      data.event_name,
      data.group_name
    );
  }

  private constructor(
    readonly totalPointCnt: number,
    readonly normalPointCnt: number,
    readonly totalBonusPointCnt: number,
    readonly bonusList: OpGiftBonus[],
    readonly eventName: string,
    readonly groupName: string | null
  ) {}
}
