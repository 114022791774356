
























































































































































































































































































import OnceButton from '@/components/OnceButton.vue';
import confetti from 'canvas-confetti';
import { OpCardList } from '@/models/opcards/OpCard';
import { OpCardRepository } from '@/repositories/OpCardRepository';
import { OpGiftRepository } from '@/repositories/OpGiftRepository';
import { OpGiftUse } from '@/models/OpGiftUse';
import filterUtils from '@/common/filterUtils';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';

@Component({
  components: { OnceButton },
  computed: { ...mapState(['op']) }
})
export default class OpGift extends Vue {
  // 印刷物等からは/register-op-gift(P0154.vue)宛にクエリパラメータでギフトコードを指定してアクセスし、
  // authGuardにて（ログイン状態に依らず）localStorageにギフトコードを保存する。
  // その後ログイン状態に応じた経路でこの画面に遷移する。

  @Watch('$auth.loading', { immediate: true })
  onLoad() {
    if (this.$auth.loading) {
      return;
    }

    if (!this.$auth.isAuthenticated) {
      return;
    }

    this.setGiftCode();
    if (localStorage.auto_use && localStorage.auto_use === 'true') {
      this.register();
      localStorage.removeItem(this.AUTO_USE_KEY);
    }
  }

  // モーダルが閉じられるとき、this.resetConfetti() を呼び出し、紙吹雪アニメーションを消去する
  // <v-dialog> の内部にて this.isDialogOpen が false に設定されることに対応するため、@Watch() を利用する
  @Watch('isDialogOpen')
  onIsDialogOpenChanged() {
    if (!this.isDialogOpen) {
      this.resetConfetti();
      this.resetConfetti = () => {};
    }
  }

  private buttonLoading = false;
  private duringUse = false;
  readonly GIFTCODE_PLACEHOLDER = '00000000';
  readonly GIFT_CODE_KEY = 'giftcode';
  readonly AUTO_USE_KEY = 'auto_use';

  private giftCode: string = '';
  private errorMsg: string = '';

  // resetConfetti は紙吹雪のアニメーションを停止します
  // この関数は、モーダルを閉じるときに呼ばれるべきです
  resetConfetti = () => {};

  private isDialogOpen = false;
  private canvasWidth = window.innerWidth; //紙吹雪Canvas要素のwidth
  private canvasHeight = window.innerHeight; //紙吹雪Canvas要素のheight

  resOpGift = {} as OpGiftUse;
  get buttonLabel(): string {
    return this.duringUse ? 'OPギフト登録中…' : 'OPギフトコードを登録する';
  }

  private setGiftCode() {
    if (localStorage.giftcode) {
      this.giftCode = localStorage.giftcode;
      localStorage.removeItem(this.GIFT_CODE_KEY);
    }

    if (this.$route.query && this.$route.query.giftcode) {
      this.giftCode = Array.isArray(this.$route.query.giftcode)
        ? ''
        : this.$route.query.giftcode;
    }
  }

  private formatGiftCode() {
    this.giftCode = filterUtils.funcs.formatToHalfUpperCase(this.giftCode);
  }

  get opCardRepo() {
    return new OpCardRepository();
  }

  get opCards() {
    return OpCardList.valueOf(
      this.$auth.user['https://one-odakyu.com/op_cards']
    );
  }

  get opGiftRepo() {
    return new OpGiftRepository();
  }

  private async register() {
    if (!(await this.$validator.validateAll())) {
      return;
    }

    this.duringUse = true;
    this.errorMsg = '';

    try {
      const res = await this.opGiftRepo.postOpGift(this.giftCode);
      this.giftCode = '';
      this.resOpGift = res;
      this.$store.commit('setOpBalance', await this.opCardRepo.getOpBalance());
      this.isDialogOpen = true;
      this.resetConfetti = this.showConfetti(this.$refs.confetti);
    } catch (err) {
      this.handleRegisterErr(err);
    } finally {
      this.duringUse = false;
    }
  }

  // eslint-disable-next-line complexity
  private handleRegisterErr(errCode: unknown) {
    switch (errCode) {
      case 40015:
        this.errorMsg = this.$msg.get('2000032', {
          errorCode: errCode
        });
        break;
      case 40016:
        this.errorMsg = this.$msg.get('2000033', {
          errorCode: errCode
        });
        break;
      case 40017:
        this.errorMsg = this.$msg.get('2000034', {
          errorCode: errCode
        });
        break;
      case 40019:
        this.errorMsg = this.$msg.get('2000034', {
          errorCode: errCode
        });
        break;
      case 40020:
        this.errorMsg = this.$msg.get('2000036', {
          errorCode: errCode
        });
        break;
      case 40021:
        this.errorMsg = this.$msg.get('2000037', {
          errorCode: errCode
        });
        break;
      case 40051:
        this.errorMsg = this.$msg.get('2000115', {
          errorCode: errCode
        });
        break;
      case 40052:
        this.errorMsg = this.$msg.get('2000115', {
          errorCode: errCode
        });
        break;
      case 40053:
        this.errorMsg = this.$msg.get('2000117', {
          errorCode: errCode
        });
        break;
      case 50301:
        this.errorMsg = this.$msg.get('2000010', {
          errorCode: errCode
        });
        break;
      default:
        this.errorMsg = this.$msg.get('2000039', {
          errorCode: errCode
        });
        break;
    }
  }

  // showConfetti は紙吹雪アニメーションを表示し、アニメーションを削除するための関数を返します
  showConfetti(element: Vue | Element | Vue[] | Element[]) {
    const _element = element as any;
    const cft = confetti.create(_element);

    cft({
      particleCount: 100,
      spread: 100,
      decay: 0.94
    });

    return cft.reset;
  }
}
