



























import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { OpCardList } from '@/models/opcards/OpCard';

@Component({
  computed: {
    ...mapState(['op'])
  }
})
export default class MyPageOpOverview extends Vue {
  get opCards() {
    return OpCardList.valueOf(
      this.$auth.user['https://one-odakyu.com/op_cards']
    );
  }
}
