import { ResOpHistory } from '@/gen/titan';

export class OpCardHistoryRecord {
  private constructor(
    readonly cardTypeCd: string,
    readonly grantedPoints: number,
    readonly grantedPointsSign: string,
    readonly parentageType: string,
    readonly processStatus: string,
    readonly storeName: string,
    readonly transactionDate: string,
    readonly usedPoints: number,
    readonly usedPointsSign: string
  ) {}

  static valueOf(data: ResOpHistory) {
    return new OpCardHistoryRecord(
      data.card_type_cd,
      data.granted_points,
      data.granted_points_sign,
      data.parentage_type,
      data.process_status,
      data.store_name,
      data.transaction_date,
      data.used_points,
      data.used_points_sign
    );
  }

  get getParentTyp() {
    return this.parentageType === '0' ? '本会員' : '家族会員';
  }

  get getFormatTxDate() {
    const year = this.transactionDate.slice(0, 4);
    const month = this.transactionDate.slice(4, 6);
    const day = this.transactionDate.slice(6, 8);
    return `${year}/${month}/${day}`;
  }
}

export class OpCardHistory {
  private constructor(readonly list: OpCardHistoryRecord[]) {}

  static valueOf(data: ResOpHistory[]) {
    return data.map(e => OpCardHistoryRecord.valueOf(e));
  }
}
