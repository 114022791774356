












































import { Component, PropSync, Vue, Ref } from 'vue-property-decorator';
import { OpUtils } from '@/common/OpUtils';
import OpRegisterForm from '@/components/OpRegisterForm/OpRegisterForm.vue';
import OpSecurityCodeRegisterForm from '@/components/OpRegisterForm/OpSecurityCodeRegisterForm.vue';

@Component({
  components: {
    OpSecurityCodeRegisterForm,
    OpRegisterForm
  }
})
export default class AddOpCardForm extends Vue {
  @Ref() readonly OpRegisterForm!: OpRegisterForm;
  @Ref() readonly OpSecurityCodeRegisterForm!: OpSecurityCodeRegisterForm;

  @PropSync('opCardNumber', { required: true })
  syncedOpCardNumber!: string[];

  @PropSync('securityCode', { required: true })
  syncedSecurityCode!: string;

  @PropSync('doSetMainCard', { type: Boolean, required: true })
  syncedDoSetMainCard!: Boolean;

  errorMsg = '';
  error = false;

  get isPointOnlyRealCard() {
    var opCardNo = this.syncedOpCardNumber.join('');
    return opCardNo.length === 12 && OpUtils.isPointOnlyRealCard(opCardNo);
  }

  onOpCardNumInput() {
    if (OpUtils.isPointOnlyRealCard(this.syncedOpCardNumber.join(''))) {
      return;
    }
    this.syncedSecurityCode = '';
  }
}
